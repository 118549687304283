body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: #000;
  }
  
  .app {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  canvas {
    width: 100% !important;
    height: 100% !important;
  }